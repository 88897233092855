.tbl {
  width: 90%;
  margin: 2em auto;
  border-collapse: collapse;
}
.tbl-head{
    height: 40px;
    background: #ececec;
    border: none;
    border-bottom: 1px solid #121212;
}
td,
th {
  text-align: left;
  padding: 8px;
  color: #5f5f5f;
}
.tbl tr{
    height: 60px;
    background: #fdfdfd;
}

td img{
    width: 35px;
    height: 45px;
}
.td-spe{
    text-align: right;
}
.td-inp{
    width: 70px;
    height: 30px;
    outline: none;
    text-align: center;
}
.td-spe button{
    width: 40px;
    height: 30px;
    background: #474747;
    border: none;
}