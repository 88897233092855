.header {
  width: 90%;
  margin: 1em auto;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid crimson; */
}

.header__sec {
  width: 40%;
  display: flex;
  align-items: center;
  /* border: 1px solid crimson; */
}

.header__sec.on-right{
  justify-content: flex-end;
}

.slct {
  width: 120px;
  height: 30px;
  padding: 0.3em 0.5em;
  background: #fff;
  border: 2px solid #bfc3c5;
  border-radius: 4px;
}

.btn {
  width: 120px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--primary-clr);
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.srch{
    width: 200px;
    height: 30px;
    padding: .5em .5em;
    border-radius: 4px;
    border: none;
    background: #ecf1f4;
    outline: none;
}

.lnk{
    text-decoration: none;
    border: none;
    background: var(--primary-clr);
    color: #fff;
    width: 120px;
    height: 40px;
    display: grid;
    place-items: center;
}