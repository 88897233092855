@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root{
  --primary-clr: #00a0c6;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #ffff;
  color: #121212;
}

.ml-1{
  margin-left: 10px;
}

.ml-2{
  margin-left: 20px;
}

.ml-3{
  margin-left: 30px;
}

.primary-clr{
  color: var(--primary-clr);
}

.green-clr{
  color: green;
}
.flex-center{
  display: flex;
  align-items: center;
}

.container{
  width: 50%;
  height: 300px;
  margin: 3em auto;
  display: grid;
  place-items: center;
}
.container > p{
  text-align: center;
}

