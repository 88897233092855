.main{
    width: 90%;
    margin: 3em auto;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid crimson; */
}

.main table{
    width: 40%;
    /* border: 1px solid black; */
}

.main table{
    width: 50%;
    height: max-content;
    background: #fff;
}

.main .t-title{
    font-weight: bold;
}
.main__tr{
    border-bottom: 2px solid #cecece;
}
.main__tr td{
    height: 80px;
    text-align: center;
    background: #fff;
    /* border: 1px solid crimson; */
}
.main__titles{
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.td-col-2{
    display: flex;
    align-items: center;
    gap: 3em;
    margin-top: 1.2em;
}
.td-btn{
    height: 40px !important;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around; 
    border: 1px solid #cecece;
}
.qunt-btn{
    background: #fff;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

.bill{
    width: 30%;
    height: 200px;
    margin: 3em auto;
    padding: .5em .5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid #cecece;
}

.bill > div{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.end{
    width: 80%;
    height: 40px;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .5em;
    border-radius: 30px;
    font-size: 14px;
    display: grid;
    place-items: center;
    background: rgb(0, 28, 65);
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
}